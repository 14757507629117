import {AxiosResponse} from "axios";
import apiClient from "../http/apiClient";
import {BaseService} from "./BaseService";
import moment from "moment";
import _ from "lodash";

export class DiscordService extends BaseService {
    constructor(props?: any) {
        super(props);

    }

    async fetchToken(code: string): Promise<any> {
        try {
            let response: AxiosResponse = await apiClient.get(`${process.env.REACT_APP_BASE_URL}/discord/connect/${code}`);
            if (response.status === 200) {
                return response.data;
            }
        } catch (e) {
            return Promise.reject('failed request');
        }
        return Promise.reject('failed request');
    }

}
