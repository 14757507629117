import {create} from "zustand";
import {ProposalService} from "../services/ProposalService";
import _ from "lodash";

interface ProposalStore {
    loading: boolean,
    service: ProposalService,
    proposals: any[],
    fetch: (refresh?: boolean) => Promise<any[]>
    fetchByUrl: (url: string) => any
}

export const useProposalStore = create<ProposalStore>()((set, get) => ({
    loading: false,
    service: new ProposalService(),
    proposals: [],
    fetch: async (refresh?: boolean): Promise<any[]> => {
        let {proposals, loading, service} = get();
        let fetchedPosts: any[] = [];
        if (!loading) {
            if (proposals.length <= 0 || refresh) {
                fetchedPosts = await service.fetchAll();
                fetchedPosts = _.orderBy(fetchedPosts, ['created'], ['desc'])
                set({proposals: fetchedPosts, loading: false})
            }
        }
        return Promise.resolve(fetchedPosts);
    },
    fetchByUrl: (url: string): any => {
        let {proposals, loading, service} = get();
        return proposals.find(x => x.url === url);
    },
}))
