import axios, {AxiosError} from 'axios'
import storageHelper from "../helpers/storageHelper";

const ApiClient = () => {
    const instance = axios.create()
    instance.interceptors.request.use(async (request) => {
        const token = storageHelper.getToken();
        const guestToken = storageHelper.getData('dao_guestToken');
        // Add Authorization header to every request if token exists
        if (token) {
            request.headers["Authorization"] = `Bearer ${token}`;
        }
        if (guestToken) {
            request.headers["Dao-Guest-Token"] = guestToken;
        }
        request.headers['Dao-App'] = 'forum';

        return request
    })

    instance.interceptors.response.use(
        (response) => {
            return response
        },
        ({code, message, request, response}: AxiosError) => {
            return response?.data;
        }
    )

    return instance
}

export default ApiClient();
