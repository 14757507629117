import React, {FC, useEffect} from "react";
import {useDebounce} from "../../hooks/useDebounce";

interface InputWithDebounceProps {
    value: any;
    className: string;
    placeholder: string;
    onChange: (value: any) => void;
}

const InputWithDebounce: FC<InputWithDebounceProps> = ({
                                                           value,
                                                           className,
                                                           placeholder,
                                                           onChange
                                                       }: InputWithDebounceProps) => {
    const [data, setData] = React.useState<any>(value); // Initialize with the provided value
    const debouncedChange = useDebounce(data, 1000);

    useEffect(() => {
        if (debouncedChange !== undefined) {
            onChange(debouncedChange);
        }
    }, [debouncedChange, onChange]);

    return (
        <input
            type="text"
            className={className}
            placeholder={placeholder}
            value={data}
            onChange={(event) => {
                setData(event.target.value);
            }}
        />
    );
};

export default InputWithDebounce;
