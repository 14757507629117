import {AxiosResponse} from "axios";
import apiClient from "../http/apiClient";
import {BaseService} from "./BaseService";
import moment from "moment";
import _ from "lodash";

export class ProposalService extends BaseService {
    constructor(props?: any) {
        super(props);

    }

    calculateReplyCount(replies: any[]): number {
        let count = replies.length;
        for (const reply of replies) {
            count += this.calculateReplyCount(reply.replies);
        }
        return count;
    }

    async fetchAll(): Promise<any> {
        try {
            let response: AxiosResponse = await apiClient.get(`${process.env.REACT_APP_BASE_URL}/proposal`);
            if (response.status === 200 && _.isArray(response.data)) {
                return Promise.resolve(response.data.map(x => ({
                    ...x,
                    replyCount: this.calculateReplyCount(x.replies)
                })));
            }
        } catch (e) {
            return Promise.reject('failed request');
        }
        return Promise.reject('failed request');
    }

    async save(post: any): Promise<any> {
        try {
            let response: AxiosResponse = await apiClient.post(`${process.env.REACT_APP_BASE_URL}/proposal/create`, post);
            if (response.status === 201) {
                return Promise.resolve(response.data);
            }
        } catch (e) {
            return Promise.reject('failed request');
        }
        return Promise.reject('failed request');
    }


    async update(post: any, id: string): Promise<any> {
        try {
            let response: AxiosResponse = await apiClient.put(`${process.env.REACT_APP_BASE_URL}/proposal/${id}/update`, post);
            if (response.status === 200) {
                return Promise.resolve(response.data);
            }
        } catch (e) {
            return Promise.reject('failed request');
        }
        return Promise.reject('failed request');
    }

    async toggleLock(proposalId: string): Promise<any> {
        try {
            let response: AxiosResponse = await apiClient.put(`${process.env.REACT_APP_BASE_URL}/proposal/toggle-lock/${proposalId}`);
            if (response.status === 200) {
                return Promise.resolve(response.data);
            }
        } catch (e) {
            return Promise.reject('failed request');
        }
        return Promise.reject('failed request');
    }

    async seen(proposalId: string): Promise<any> {
        try {
            let response: AxiosResponse = await apiClient.put(`${process.env.REACT_APP_BASE_URL}/proposal/seen/${proposalId}`);
            if (response.status === 200) {
                return Promise.resolve(response.data);
            }
        } catch (e) {
            return Promise.reject('failed request');
        }
        return Promise.reject('failed request');
    }

    async delete(id: string): Promise<any> {
        try {
            const response: AxiosResponse = await apiClient.delete(
                `${process.env.REACT_APP_BASE_URL}/proposal/${id}/delete`
            );
            if (response.status === 200) {
                return Promise.resolve(response.data);
            }
        } catch (e) {
            return Promise.reject('Failed to upvote proposal');
        }
        return Promise.reject('Failed to upvote proposal');
    }

    async upvote(id: string): Promise<any> {
        try {
            const response: AxiosResponse = await apiClient.get(
                `${process.env.REACT_APP_BASE_URL}/proposal/upvote/${id}`
            );
            if (response.status === 200) {
                return Promise.resolve();
            }
        } catch (e) {
            return Promise.reject('Failed to upvote proposal');
        }
        return Promise.reject('Failed to upvote proposal');
    }
    async downvote(id: string): Promise<any> {
        try {
            const response: AxiosResponse = await apiClient.get(
                `${process.env.REACT_APP_BASE_URL}/proposal/downvote/${id}`
            );
            if (response.status === 200) {
                return Promise.resolve();
            }
        } catch (e) {
            return Promise.reject('Failed to upvote proposal');
        }
        return Promise.reject('Failed to upvote proposal');
    }
}
