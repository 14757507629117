import React, {FC, useEffect, useState} from 'react';
import MDEditor from '@uiw/react-md-editor';
import moment from "moment";
import {useSessionStore} from "../../../../stores/sessionStore";
import {Stack} from "@mui/material";
import {ContextStore} from "@uiw/react-md-editor/src/Context";
import {toast} from "react-toastify";
import {ReplyService} from "../../../../services/ReplyService";
import {useProposalStore} from "../../../../stores/proposalStore";
import Swal from 'sweetalert2'
import Username from "../../../../components/Username/Username";

interface ProposalDetailProps {
    reply: any,
    proposal: any,
}

const ReplyBox: FC<ProposalDetailProps> = ({reply, proposal}) => {
    const [replyOn, setReplyOn] = useState<boolean>(false)
    const sessionStore = useSessionStore();
    const [content, setContent] = useState('')
    const replyService = new ReplyService();
    const proposalStore = useProposalStore();

    const [thisReplyEditOn, setThisReplyEditOn] = useState<boolean>(false)
    const [thisReplyContent, setThisReplyContent] = useState('')

    useEffect(() => {
        console.log(reply)
    }, []);

    return (
        <>

            <div className='post'>
                <div className='post-left'>
                    <div className='user-avatar'>
                        <img
                            src={`${process.env.REACT_APP_BASE_URL}/image/${reply.owner.username}`}
                            alt={`${reply.owner.username}_profile_picture`}></img>
                    </div>
                </div>
                <div className='post-content w-100'>
                    <div className='user-info'>
                        <div className='post-info'>
                            <>
                                <Username user={reply.owner}/>
                                <div className='post-date'>
                                    <i className="fa-regular fa-calendar me-1" color="white"></i>
                                    {moment(reply.createdAt).format("DD MMM, YYYY")}
                                </div>


                            </>
                        </div>

                        <div className='edit-buttons'>
                            <>

                                {
                                    sessionStore.username === reply.owner.username &&
                                    <button title='Edit' className='post-button edit-button'
                                            onClick={() => {
                                                setThisReplyEditOn(true)
                                                setThisReplyContent(reply.content)
                                            }}>
                                        <i className="ti-pencil"></i>
                                    </button>
                                }
                                {
                                    ((sessionStore.username === reply.owner.username && !proposal.locked) ||
                                    sessionStore.isAdmin()) &&
                                    <button title='Delete' className='post-button delete-button'
                                            onClick={() => {
                                                Swal.fire({
                                                    title: "Are you sure?",
                                                    text: "You won't be able to revert this!",
                                                    icon: "warning",
                                                    showCancelButton: true,
                                                    confirmButtonColor: "#3085d6",
                                                    cancelButtonColor: "#d33",
                                                    confirmButtonText: "Yes, delete it!"
                                                }).then((result) => {
                                                    if (result.isConfirmed) {
                                                        replyService.delete(reply.id).then(() => {
                                                            proposalStore.fetch(true).then(() => {
                                                                Swal.fire({
                                                                    title: "Deleted!",
                                                                    text: "Your reply has been deleted.",
                                                                    icon: "success"
                                                                });
                                                            })
                                                        })

                                                    }
                                                });
                                            }}><i
                                        className="ti-trash"></i></button>
                                }
                                {
                                    thisReplyEditOn &&
                                    <button title='Close' className='post-button close-editing-button' onClick={() => {
                                        setThisReplyEditOn(false)
                                        setThisReplyContent('')
                                    }}>
                                        <i
                                            className="ti-close"></i>
                                    </button>
                                }

                            </>
                        </div>

                    </div>
                    {
                        !thisReplyEditOn ?
                            <MDEditor.Markdown source={reply.content} style={{whiteSpace: 'pre-wrap'}}/> :
                            <>
                                <MDEditor
                                    preview={'edit'}
                                    height={400}
                                    value={thisReplyContent}
                                    onChange={(value?: string, event?: React.ChangeEvent<HTMLTextAreaElement>, state?: ContextStore) => {
                                        setThisReplyContent(value || '')
                                    }}
                                />
                                <div className={'w-100 d-flex justify-content-end'}>
                                    <button className='btn btn-primary mt-2 text-end' title='reply'
                                            onClick={() => {
                                                let data = {
                                                    content: thisReplyContent,
                                                }
                                                replyService.update(data, reply.id).then(() => {
                                                    proposalStore.fetch(true).then(() => {
                                                        toast.success('Reply has been updated')
                                                        setThisReplyEditOn(false)
                                                    })
                                                })
                                            }}>
                                        <i className="fa-regular fa-paper-plane me-1"></i>Update
                                    </button>
                                </div>
                            </>

                    }
                    <div className='post-bottom'>
                        <div className='post-bottom-left'>
                            {
                                !proposal.locked &&
                                <button className='reply-button' title='Reply' onClick={() => {
                                    if (!sessionStore.hasSession() || !sessionStore.isDiscordVerified()) {
                                        toast.warning('Only verified members can reply to a reply')
                                        return;
                                    }
                                    setReplyOn(true)
                                }}>
                                    <i className="fa-solid fa-reply me-1"></i>
                                    Reply
                                </button>
                            }
                            {/* <span className='mx-3'>
                                        <i className="fa-regular fa-heart me-1"></i>
                                        42
                                    </span>*/}

                        </div>
                        <div className='post-bottom-right'>
                                    <span className='like-buttons'>
                                        <button onClick={() => {
                                            if (!sessionStore.hasSession() || !sessionStore.isDiscordVerified()) {
                                                toast.warning('Only verified members can upvote a reply');
                                                return
                                            }
                                            if (reply.vote && reply.vote === 'UPVOTE') return
                                            replyService.upvote(reply.id).then(() => {
                                                toast.success('Reply has been upvoted')
                                                proposalStore.fetch(true)
                                            }).catch(() => {
                                                toast.error('Reply upvote has been failed')
                                            })
                                        }}>
                                            <i className={`${reply.vote && reply.vote === 'UPVOTE' ? 'fa-solid' : 'fa-regular'} fa-thumbs-up`}></i>{reply.upvoteCount}
                                        </button>
                                        <button onClick={() => {
                                            if (!sessionStore.hasSession() || !sessionStore.isDiscordVerified()) {
                                                toast.warning('Only verified members can downvote a reply');
                                                return
                                            }
                                            if (reply.vote && reply.vote === 'DOWNVOTE') return
                                            replyService.downvote(reply.id).then(() => {
                                                toast.success('Reply has been downvoted')
                                                proposalStore.fetch(true)
                                            }).catch(() => {
                                                toast.error('Reply downvote has been failed')
                                            })
                                        }}>
                                            <i className={`${reply.vote && reply.vote === 'DOWNVOTE' ? 'fa-solid' : 'fa-regular'} fa-thumbs-down`}></i>{reply.downvoteCount}
                                        </button>
                                    </span>
                        </div>
                    </div>
                </div>
            </div>
            {
                replyOn &&
                <Stack direction={'column'} spacing={2} sx={{mt: 5}}>
                    <Stack direction={'row'} justifyContent={'space-between'}>
                            <span>
                                <i className="fa-brands fa-markdown text-white"
                                   color={'inherit'} style={{fontSize: 22, marginRight: 5}}></i>
                                <h5 className={'text-white d-inline-flex'}>Reply to this comment</h5>
                            </span>
                        <i className="ti-close text-white" style={{cursor: 'pointer'}}
                           onClick={() => {
                               setReplyOn(false)
                           }}></i>
                    </Stack>
                    <div data-color-mode="dark">
                        <MDEditor
                            preview={'edit'}
                            height={400}
                            value={content}
                            onChange={(value?: string, event?: React.ChangeEvent<HTMLTextAreaElement>, state?: ContextStore) => {
                                setContent(value || '')
                            }}
                        />
                    </div>
                    <Stack direction={'row'} justifyContent={'end'}>
                        <button className='btn btn-primary' title='reply' onClick={() => {
                            let data = {
                                content: content,
                                parentId: reply.id,
                            }
                            replyService.save(data).then(() => {
                                proposalStore.fetch(true)
                                toast.success('Reply has sent')
                                setReplyOn(false)
                            })
                        }}>
                            <i className="fa-regular fa-paper-plane me-1"></i>Send
                        </button>
                    </Stack>
                </Stack>
            }
            {
                reply.replies.length > 0 &&
                <div className='replies'>
                    {
                        reply.replies.map((reply: any, index: number) => (
                            <ReplyBox key={index} reply={reply} proposal={proposal}></ReplyBox>
                        ))
                    }
                </div>
            }

        </>
    );
}

export default ReplyBox;
