import React, {FC, useEffect, useState} from "react";
import moment from "moment/moment";
import {NavLink} from "react-router-dom";
import {Rating} from "@mui/material";
import {toast} from "react-toastify";
import {useProposalStore} from "../../../../stores/proposalStore";
import {useSessionStore} from "../../../../stores/sessionStore";
import {ProposalService} from "../../../../services/ProposalService";

interface PostCardProps {
    proposal: any,
    edit: (proposal: any) => void
}

const ProposalCard: FC<PostCardProps> = ({proposal, edit}: PostCardProps) => {


    const proposalStore = useProposalStore()
    const sessionStore = useSessionStore()
    const proposalService = new ProposalService()


    return (
        <tr>
            <th scope="row" className='topic-title'>
                <h3>
                    <NavLink to={`/proposal/${proposal.url}`} className="me-2">
                        {
                            proposal.locked ?
                                <i className="ti-lock me-2"></i> :
                                <i className="bi bi-fire me-2"></i>
                        }
                        {
                            proposal.title
                        }
                    </NavLink>
                    {/*{
                        showEdit &&
                        <button title="Edit" className="edit-proposal">
                            <i className="ti-pencil" onClick={() => {
                                edit(proposal)
                            }}></i>
                        </button>
                    }*/}
                </h3>
                <p>
                    {/* <MDEditor.Markdown source={post.content} style={{whiteSpace: 'pre-wrap'}}/>*/}
                </p>
                <span className='topic-status locked'>
                    {
                        proposal.locked ? 'Locked' : 'Active'
                    }
                </span>
                {
                    !proposal.reviewed &&
                    <span className='topic-status locked'>
                        Under Review
                    </span>
                }
                <span className='topic-started'><i
                    className="ti-calendar me-2"></i>{moment(proposal.createdAt).format("DD MMM, YYYY")}</span>
                <span className='topic-activity'>
                    <i className="ti-time me-2"></i>
                    <>
                        {
                            moment(proposal.createdAt).fromNow()
                        }
                    </>
                </span>
            </th>
            <td>{proposal.replyCount}</td>
            <td>{proposal.seenCount}</td>
            <td>
            <span className='like-buttons'>
                                                        <button onClick={() => {
                                                            if (!sessionStore.hasSession() || !sessionStore.isDiscordVerified()) {
                                                                toast.warning('Only verified members can upvote a reply');
                                                                return
                                                            }
                                                            if (proposal.locked) {
                                                                toast.warning('Locked proposals cannot be rated');
                                                                return
                                                            }
                                                            if (proposal.vote && proposal.vote === 'UPVOTE') return
                                                            proposalService.upvote(proposal.id).then(() => {
                                                                toast.success('Reply has been upvoted')
                                                                proposalStore.fetch(true)
                                                            }).catch(() => {
                                                                toast.error('Reply upvote has been failed')
                                                            })
                                                        }}>
                                                            <i className={`${proposal.vote && proposal.vote === 'UPVOTE' ? 'fa-solid' : 'fa-regular'} fa-thumbs-up`}></i>{proposal.upvoteCount}
                                                        </button>
                                                        <button onClick={() => {
                                                            if (!sessionStore.hasSession() || !sessionStore.isDiscordVerified()) {
                                                                toast.warning('Only verified members can downvote a reply');
                                                                return
                                                            }
                                                            if (proposal.locked) {
                                                                toast.warning('Locked proposals cannot be rated');
                                                                return
                                                            }
                                                            if (proposal.vote && proposal.vote === 'DOWNVOTE') return
                                                            proposalService.downvote(proposal.id).then(() => {
                                                                toast.success('Reply has been downvoted')
                                                                proposalStore.fetch(true)
                                                            }).catch(() => {
                                                                toast.error('Reply downvote has been failed')
                                                            })
                                                        }}>
                                                            <i className={`${proposal.vote && proposal.vote === 'DOWNVOTE' ? 'fa-solid' : 'fa-regular'} fa-thumbs-down`}></i>{proposal.downvoteCount}
                                                        </button>
                                                    </span>
            </td>
        </tr>
    );
}


export default ProposalCard;
