import React, {FC, useRef} from 'react';
import logo from '../../assets/logo.svg'
import sun from '../../assets/light-mode.svg'
import {NavLink, useNavigate} from "react-router-dom"
import {useMediaQuery} from 'react-responsive'
import Login from "../Login/Login";
import {useSessionStore} from "../../stores/sessionStore";
import Register from '../Register/Register';

const Desktop = ({children}: any) => {
    const isDesktop = useMediaQuery({minWidth: 1180})
    return isDesktop ? children : null
}

const Mobile = ({children}: any) => {
    const isMobile = useMediaQuery({maxWidth: 1179})
    return isMobile ? children : null
}

interface HeaderProps {
}

const Header: FC<HeaderProps> = () => {
    const navigate = useNavigate();
    const sessionStore = useSessionStore();

    const loginModal = useRef<{
        show: () => void,
        close: () => void,
    }>();
    const registerModal = useRef<{
        show: () => void,
        close: () => void,
    }>();

    return (
        <header>
            <Login ref={loginModal}/>
            <Register ref={registerModal}/>
            <div className='container'>
                <div className='row align-items-center g-0'>
                    <div className='col-lg-3 col-6'>
                        <NavLink to="/">
                            <img src={logo} alt='logo'></img>
                        </NavLink>
                    </div>
                    <div className='col-lg-9 col-6'>
                        <Desktop>
                            <div className='d-flex justify-content-end align-items-center'>

                                <ul className='main-nav navbar'>

                                    <li className='nav-item'>
                                        <NavLink to="/"><i className="ti-comments me-2"></i>Proposals</NavLink>
                                    </li>
                                    <li className='nav-item d-none'>
                                        <NavLink to="faqs"><i className="ti-package me-2"></i>Planning</NavLink>
                                    </li>
                                    {
                                        !sessionStore.hasSession() &&
                                        <>
                                            <li className='nav-item'>
                                                <button type="button" onClick={() => {
                                                    loginModal.current?.show();
                                                }}>
                                                    <i className="ti-user me-2"></i>Login
                                                </button>
                                            </li>
                                            <li>
                                                <button type="button" onClick={() => {
                                                    registerModal.current?.show();
                                                }}>
                                                    <i className="ti-key me-2"></i>Register
                                                </button>
                                            </li>
                                        </>
                                    }
                                    {
                                        sessionStore.hasSession() &&
                                        <>
                                            <li className='nav-item'>
                                                <NavLink to="/account"><i className="ti-user me-2"></i>Account</NavLink>
                                            </li>
                                            <li>
                                                <button type="button" onClick={() => {
                                                    navigate('logout')
                                                }}>
                                                    <i className='ti-power-off me-2'></i>Logout
                                                </button>
                                            </li>
                                        </>
                                    }
                                    <li className='nav-item notification d-none'>
                                        <button className="notification-button dropdown-toggle" type="button"
                                                data-bs-toggle="dropdown" aria-expanded="false"><i
                                            className="ti-bell"></i><span>0</span></button>
                                        <ul className="dropdown-menu">
                                            <li>
                                                <h5><a href="#!" title='Announce Title'>Announce Title</a></h5>
                                                <p>
                                                    <a href="#!" title='Announce Title'>
                                                        It is a long established fact that a reader will be distracted
                                                        by the readable content of a page when looking at its layout.
                                                    </a>
                                                </p>
                                                <div className='notification-info'>
                                                    <span>12.12.2023 18:20</span>
                                                </div>
                                            </li>
                                            <li>
                                                <h5><a href="#!" title='Announce Title'>Announce Title</a></h5>
                                                <p>
                                                    <a href="#!" title='Announce Title'>
                                                        It is a long established fact that a reader will be distracted
                                                        by the readable content of a page when looking at its layout.
                                                    </a>
                                                </p>
                                                <div className='notification-info'>
                                                    <span>12.12.2023 18:20</span>
                                                </div>
                                            </li>
                                            <span className='remove-notifications'>
                                                <button><i className='ti-trash'></i> REMOVE ALL</button>
                                            </span>
                                        </ul>
                                    </li>
                                </ul>

                                <button className='switch-mode d-none'>
                                    <img src={sun} alt='logo'></img>
                                </button>
                            </div>
                        </Desktop>

                        <Mobile>
                            <ul className='mobile-links main-nav navbar'>
                                <li className='nav-item'>
                                    <NavLink to="/"><i className="ti-comments me-2"></i></NavLink>
                                </li>
                                <li className='nav-item d-none'>
                                    <NavLink to="faqs"><i className="ti-package me-2"></i></NavLink>
                                </li>
                                {
                                    !sessionStore.hasSession() &&
                                    <>
                                        <li className='nav-item'>
                                            <button type="button" onClick={() => {
                                                loginModal.current?.show();
                                            }}>
                                                <i className="ti-user me-2"></i>
                                            </button>
                                        </li>
                                        <li>
                                            <button type="button" onClick={() => {
                                                registerModal.current?.show();
                                            }}>
                                                <i className="ti-key me-2"></i>
                                            </button>
                                        </li>
                                    </>
                                }
                                {
                                    sessionStore.hasSession() &&
                                    <>
                                        <li className='nav-item'>
                                            <NavLink to="account"><i className="ti-user me-2"></i></NavLink>
                                        </li>
                                        <li>
                                            <button type="button" onClick={() => {
                                                sessionStore.logout()
                                                navigate('logout')
                                            }}>
                                                <i className='ti-power-off me-2'></i>
                                            </button>
                                        </li>

                                    </>
                                }
                                <li className='nav-item notification d-none'>
                                    <button className="notification-button dropdown-toggle" type="button"
                                            data-bs-toggle="dropdown" aria-expanded="false"><i
                                        className="ti-bell"></i><span>0</span></button>
                                    <ul className="dropdown-menu">
                                        <li>
                                            <h5><a href="#!" title='Announce Title'>Announce Title</a></h5>
                                            <p>
                                                <a href="#!" title='Announce Title'>
                                                    It is a long established fact that a reader will be distracted by
                                                    the readable content of a page when looking at its layout.
                                                </a>
                                            </p>
                                            <div className='notification-info'>
                                                <span>12.12.2023 18:20</span>
                                            </div>
                                        </li>
                                        <li>
                                            <h5><a href="#!" title='Announce Title'>Announce Title</a></h5>
                                            <p>
                                                <a href="#!" title='Announce Title'>
                                                    It is a long established fact that a reader will be distracted by
                                                    the readable content of a page when looking at its layout.
                                                </a>
                                            </p>
                                            <div className='notification-info'>
                                                <span>12.12.2023 18:20</span>
                                            </div>
                                        </li>
                                        <span className='remove-notifications'>
                                                <button><i className='ti-trash'></i> REMOVE ALL</button>
                                            </span>
                                    </ul>
                                </li>
                            </ul>
                            <div className='d-flex justify-content-end d-none'>
                                <button className="menu-control" type="button" data-bs-toggle="offcanvas"
                                        data-bs-target="#offcanvasMenu" aria-controls="offcanvasMenu">

                                </button>
                            </div>
                        </Mobile>
                    </div>
                </div>
            </div>
            <button className="btn btn-primary d-none" type="button" data-bs-toggle="offcanvas"
                    data-bs-target="#offcanvasMenu" aria-controls="offcanvasMenu">
                x
            </button>

            <div className="offcanvas offcanvas-start offcanvas-menu" data-bs-scroll="true" tabIndex={-1}
                 id="offcanvasMenu" aria-labelledby="offcanvasMenuLabel">
                <div className="offcanvas-header">
                    <h5 className="offcanvas-title" id="offcanvasMenuLabel">Menu</h5>
                    <button type="button" className="close-button" data-bs-dismiss="offcanvas" aria-label="Close"><i
                        className="fa-regular fa-circle-xmark"></i></button>
                </div>
                <div className="offcanvas-body">

                    <ul className='mobile-nav navbar'>
                        <li className='nav-item' data-bs-dismiss="offcanvas">
                            <NavLink to="/">DASHBOARD</NavLink>
                        </li>
                        <li className='nav-item' data-bs-dismiss="offcanvas">
                            <NavLink to="governance">GOVERNANCE</NavLink>
                        </li>
                        <li className='nav-item' data-bs-dismiss="offcanvas">
                            <NavLink to="faqs">FAQS</NavLink>
                        </li>
                    </ul>

                    <a href='https://hydranet.ai' className='button button-secondary w-100 d-block'>BACK HOME</a>

                </div>
            </div>

        </header>
    );

}


export default Header;
