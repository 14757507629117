import React, {FC} from 'react';

interface FaqsProps {
}

const Faqs: FC<FaqsProps> = () => {

    return (
        <section className='section-faqs mt-4'>
            <div className='container'>
                <div className='row'>
                    <div className='col-lg-12 mb-5'>
                        <div className='page-title d-flex justify-content-start align-items-center'>
                            <div className='title-icon'><i className="fa-solid fa-lightbulb"></i></div>
                            <div className='title-content'>
                                <h1>FAQs</h1>
                                <p>Frequently Asked Questions</p>
                            </div>
                        </div>
                    </div>

                    <div className='col-12'>
                        <div className="accordion faq-accordion" id="faqsAccordion">
                            <div className="accordion-item">
                                <h2 className="accordion-header">
                                    <button className="accordion-button collapsed" type="button"
                                            data-bs-toggle="collapse" data-bs-target="#tab-01" aria-expanded="true"
                                            aria-controls="tab-01">
                                        What is the Purpose of the Community?
                                    </button>
                                </h2>
                                <div id="tab-01" className="accordion-collapse collapse show">
                                    <div className="accordion-body">
                                        <p>Hydranet is a community-based, DAO-controlled project that draws its core
                                            strengths from its dedicated, organic following. The community serves as the
                                            DAOs support network, and vice-versa. Important decisions are made by
                                            community voting. Business plans and goals are relayed by the team to the
                                            community on both an as-needed basis and in the form of weekly and monthly
                                            project status updates. The community, in turn, provides feedback, votes
                                            with their wallets, and proposes ideas for future votes. You too can be part
                                            of the Hydranet community.</p>

                                    </div>
                                </div>
                            </div>
                            <div className="accordion-item">
                                <h2 className="accordion-header">
                                    <button className="accordion-button collapsed" type="button"
                                            data-bs-toggle="collapse" data-bs-target="#tab-02" aria-expanded="false"
                                            aria-controls="tab-02">
                                        What is DAO and How Does It Work?
                                    </button>
                                </h2>
                                <div id="tab-02" className="accordion-collapse collapse">
                                    <div className="accordion-body">
                                        <p>"DAO" is short for "Decentralized Autonomous Organization". Hydranet is
                                            managed through cooperation between the community and the Hydranet team.
                                            Proposals are arranged on the Snapshot platform so that HDN holders can vote
                                            on important topics, and the team then implements those decisions
                                            accordingly.</p></div>
                                </div>
                            </div>
                            <div className="accordion-item">
                                <h2 className="accordion-header">
                                    <button className="accordion-button collapsed" type="button"
                                            data-bs-toggle="collapse" data-bs-target="#tab-03" aria-expanded="false"
                                            aria-controls="tab-03">
                                        What is the Purpose of the HDN Token?
                                    </button>
                                </h2>
                                <div id="tab-03" className="accordion-collapse collapse">
                                    <div className="accordion-body">
                                        HDN is the governance token for the Hydranet project. In addition to voting, HDN
                                        holders will benefit from the trades on the DEX by the design of our Tokenomics.
                                        By offering HDN sales in the form of bonds directly from the Hydranet website,
                                        the team is able to raise funds for development, infrastructure, and marketing.
                                        Hydranet is and always has been community-driven, and we cannot overstate our
                                        gratitude for all the support we've received.
                                    </div>
                                </div>
                            </div>

                            <div className="accordion-item">
                                <h2 className="accordion-header">
                                    <button className="accordion-button collapsed" type="button"
                                            data-bs-toggle="collapse" data-bs-target="#tab-04" aria-expanded="false"
                                            aria-controls="tab-01">
                                        What Are the Hydranet Team's Experiences?
                                    </button>
                                </h2>
                                <div id="tab-04" className="accordion-collapse collapse">
                                    <div className="accordion-body">
                                        The Hydranet team is made up of a diverse group of volunteers, most of whom
                                        dedicate their time to development and management despite also working full-time
                                        jobs. If you would like to learn more about individual team members and their
                                        respective backgrounds and work experiences, you can read about them in a
                                        three-part series on our Medium site or Website blog section: Part I , Part II ,
                                        Part III

                                    </div>
                                </div>
                            </div>
                            <div className="accordion-item">
                                <h2 className="accordion-header">
                                    <button className="accordion-button collapsed" type="button"
                                            data-bs-toggle="collapse" data-bs-target="#tab-05" aria-expanded="false"
                                            aria-controls="tab-02">
                                        How does Hydranet address regulatory concerns?
                                    </button>
                                </h2>
                                <div id="tab-05" className="accordion-collapse collapse">
                                    <div className="accordion-body">
                                        The Hydranet team runs continuous dialogs with lawyers to minimize the risk of
                                        regulatory consequences. Measures are also actively being taken for this
                                        purpose. One example is the latest Tokenomics version and its strategic
                                        liquidity suggestion. The Hydranet team and community are here for a successful
                                        DEX, and we have to make sure this is not inhibited because of legal issues.
                                    </div>
                                </div>
                            </div>
                            <div className="accordion-item">
                                <h2 className="accordion-header">
                                    <button className="accordion-button collapsed" type="button"
                                            data-bs-toggle="collapse" data-bs-target="#tab-06" aria-expanded="false"
                                            aria-controls="tab-03">
                                        Can I contribute to the development of the Hydranet DEX and the project overall?
                                    </button>
                                </h2>
                                <div id="tab-06" className="accordion-collapse collapse">
                                    <div className="accordion-body">
                                        By joining the community, you can be involved in the decision-making process at
                                        its most basic level. Much of our content production, moderation, and social
                                        media management have come from volunteers. We have international community
                                        representatives for an impressive number of countries and languages. These
                                        efforts significantly enhance communication between the team and community
                                        itself. We invite you to join the Hydranet Discord channel and try out the
                                        testnet DEX for yourself!
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Faqs;
