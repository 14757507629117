import React, {FC, useEffect, useState} from 'react'
import {useSessionStore} from "../../stores/sessionStore";

interface LogoutProps {
}

const Logout: FC<LogoutProps> = () => {

    const sessionStore = useSessionStore();


    useEffect(() => {
        sessionStore.logout()
        window.location.replace('/')
    }, []);


    return (
        <>
        </>
    )
}

export default Logout;
