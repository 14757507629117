import React, {useEffect} from 'react';
import {BrowserRouter, Navigate, Route, Routes} from 'react-router-dom';
import Layout from './layout/Layout/Layout';
import Dashboard from './pages/Dashboard/Dashboard';
import Governance from './pages/Governance/Governance';
import Faqs from './pages/Faqs/Faqs';
import ProposalDetail from "./pages/ProposalDetail/ProposalDetail";
import Account from './pages/Account/Account';
import './App.css';
import './icons.css';
import {useSessionStore} from './stores/sessionStore';
import NotFound from "./pages/NotFound/NotFound";
import DiscordAuthorize from "./pages/DiscordAuthorize/DiscordAuthorize";
import {createTheme, ThemeProvider} from "@mui/material";
import Logout from "./pages/Logout/Logout";


// Your PrivateRoute component
const PrivateRoute: React.FC = () => {
    // Your authentication logic here
    const sessionStore = useSessionStore();

    return sessionStore.hasSession() ? (
        // If authenticated, render the provided element
        <Routes>
            <Route path='*' element={<Account/>}/>
        </Routes>
    ) : (
        // If not authenticated, redirect to 404
        <Navigate to="/404"/>
    );
};

function App() {

    const sessionStore = useSessionStore();

    useEffect(() => {
        sessionStore.init();
    }, []);

    return (
        <ThemeProvider theme={createTheme({palette: {mode: 'dark'}})}>
            <BrowserRouter>
                <Routes>
                    <Route path='*' element={<Layout/>}>
                        <Route index path='*' element={<Dashboard/>}/>
                        <Route path='governance' element={<Governance/>}/>
                        <Route path='faqs' element={<Faqs/>}/>
                        <Route path='proposal/:url' element={<ProposalDetail/>}/>
                        <Route path="account" element={<PrivateRoute/>}/>
                        <Route path='authorize' element={<DiscordAuthorize/>}/>
                        <Route path="404" element={<NotFound/>}/>
                        <Route path="logout" element={<Logout/>}/>
                    </Route>
                </Routes>
            </BrowserRouter>
        </ThemeProvider>
    );
}

export default App;
