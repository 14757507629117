const EncryptionHelper = () => {


    const encryptBase64 = (obj: any): string => {
        return obj ? btoa(unescape(encodeURIComponent(JSON.stringify(obj)))) : ''
    }

    const decryptBase64 = (str: string): any => {
        return str && str.length > 0 ? JSON.parse(decodeURIComponent(escape(atob(str)))) : null;
    }

    return {
        encryptBase64,
        decryptBase64
    };
}
export default EncryptionHelper();
