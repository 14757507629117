import React, {FC, forwardRef, useEffect, useImperativeHandle, useRef, useState} from 'react';
import {AuthenticationService} from "../../services/AuthenticationService";
import {useSessionStore} from "../../stores/sessionStore";
import InputWithDebounce from "../InputWithDebounce/InputWithDebounce";
import _ from "lodash";
import {UserService} from "../../services/UserService";

interface RegisterProps {
}

const Register = forwardRef<any, RegisterProps>(({}, ref) => {

    const authService = new AuthenticationService();
    const sessionStore = useSessionStore();

    useImperativeHandle(ref, () => ({}));


    const [username, setUsername] = useState('')
    // const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [repeatPassword, setRepeatPassword] = useState('')
    const [error, setError] = useState<string[]>([])
    const [usernameExists, setUsernameExists] = useState<boolean | undefined>()
    const userService = new UserService();

    const myModalRef = useRef<HTMLDivElement | null>(null);

    const openModal = () => {
        // Use Bootstrap's modal('show') method to open the modal
        if (myModalRef.current) {
            // @ts-ignore: Bootstrap method
            $(myModalRef.current).modal("show");
        }
    };

    const closeModal = () => {
        // Use Bootstrap's modal('hide') method to close the modal
        if (myModalRef.current) {
            // @ts-ignore: Bootstrap method
            $(myModalRef.current).modal("hide");
        }
    };

    const handleButtonClick = () => {
        // Do something before dismissing the modal if needed
        closeModal();
    };

    useImperativeHandle(ref, () => ({
        show() {
            setError([])
            setUsername('')
            setPassword('')
            setRepeatPassword('')
            openModal();
        },
        close() {
            closeModal();
        }
    }));

    const isStrongPassword = (password: string) => {
        // Minimum Length (8 characters)
        if (password.length < 8) {
            console.log("Password must be at least 8 characters long");
            return false;
        }

        // Mix of Uppercase and Lowercase Letters
        if (!/[a-z]/.test(password) || !/[A-Z]/.test(password)) {
            console.log("Password must include both uppercase and lowercase letters");
            return false;
        }

        // Include at Least One Numeric Digit (0-9)
        if (!/\d/.test(password)) {
            console.log("Password must include at least one numeric digit");
            return false;
        }

        // Include at Least One Special Character
        if (!/[!@#$%^&*()_+{}\[\]:;<>,.?~\\/-]/.test(password)) {
            console.log("Password must include at least one special character");
            return false;
        }

        // All checks passed, the password is strong
        return true;
    }


    useEffect(() => {
        const errors = [];

        // Minimum Length (8 characters)
        if (password.length < 8) {
            errors.push("Password must be at least 8 characters long");
        }

        // Mix of Uppercase and Lowercase Letters
        if (!/[a-z]/.test(password) || !/[A-Z]/.test(password)) {
            errors.push("Password must include both uppercase and lowercase letters");
        }

        // Include at Least One Numeric Digit (0-9)
        if (!/\d/.test(password)) {
            errors.push("Password must include at least one numeric digit");
        }

        // Include at Least One Special Character
        if (!/[!@#$%^&*()_+{}\[\]:;<>,.?~\\/-]/.test(password)) {
            errors.push("Password must include at least one special character");
        }

        if (password !== repeatPassword) {
            errors.push("Password dot not match");
        }

        setError(errors)
    }, [password, repeatPassword]);

    return (
        <>
            <div className="modal fade" id="registerModal" data-bs-backdrop="static" data-bs-keyboard="false"
                 ref={myModalRef}
                 aria-labelledby="loginModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-lg modal-dialog-centered user-modal">
                    <div className="modal-content">
                        <div className="modal-body">
                            <div className='modal-wrapper align-items-start'>
                                <div className='modal-image'>
                                </div>

                                <div className='form-content'>
                                    <div className='user-modal-header'>
                                        <h3>Register</h3>
                                        <button type="button" className="close-modal" onClick={() => {
                                            closeModal()
                                        }}
                                                aria-label="Close"><i className='ti-close'></i></button>
                                    </div>

                                    <form className='user-form needs-validation' noValidate>
                                        <div className="form-row mb-3">
                                            <label htmlFor="usernameInput" className="form-label">Username</label>
                                            <InputWithDebounce value={username} className={'form-control'}
                                                               placeholder={'Enter username'} onChange={(data) => {
                                                let urlStrippedUsername = data.trim()
                                                setUsername(urlStrippedUsername)
                                                if (urlStrippedUsername.length > 0 && username !== urlStrippedUsername ) {
                                                    userService.checkIfUserExists(urlStrippedUsername).then(resp => {
                                                        setUsernameExists(resp.result)
                                                    })
                                                }
                                            }}/>
                                            {
                                                _.isUndefined(usernameExists) ?
                                                    <i className='ti-user'></i> :
                                                    <>
                                                        {
                                                            !usernameExists ?
                                                                <i className='ti-check' title={'username ok'}></i> :
                                                                <i className='ti-close' title={'username exists'}></i>
                                                        }
                                                    </>
                                            }

                                            {
                                                !_.isUndefined(usernameExists) &&
                                                <>
                                                    {
                                                        !usernameExists ?
                                                            <div className="valid-feedback">
                                                                Looks good!
                                                            </div> :
                                                            <div className="invalid-feedback">
                                                                Please choose a username.
                                                            </div>
                                                    }
                                                </>
                                            }
                                        </div>
                                        <div className="form-row mb-3">
                                            <label htmlFor="passwordInputRegister" className="form-label">Password</label>
                                            <input type="password" className="form-control" id="passwordInputRegister"
                                                   placeholder="Enter password" required value={password}
                                                   onChange={(event) => {
                                                       setPassword(event?.target?.value)
                                                   }}></input>
                                            <i className='ti-key'></i>
                                        </div>
                                        <div className="form-row mb-3">
                                            <label htmlFor="passwordInputRepeat" className="form-label">Repeat Password</label>
                                            <input type="password" className="form-control" id="passwordInputRepeat"
                                                   placeholder="Repeat password" required value={repeatPassword}
                                                   onChange={(event) => {
                                                       setRepeatPassword(event?.target?.value)
                                                   }}></input>
                                            <i className='ti-key'></i>
                                        </div>
                                        {
                                            error.length > 0 &&
                                            <div className={'error-messages'}>
                                                <ul style={{listStyleType: 'circle'}}>
                                                    {error.map((errorMessage, index) => (
                                                        <li key={index} className="danger">
                                                            <i className="bi bi-exclamation-diamond-fill me-1"></i>{errorMessage}
                                                        </li>
                                                    ))}
                                                </ul>
                                            </div>
                                        }
                                        <button className='user-button' type="button"
                                                onClick={() => {
                                                    if (isStrongPassword(password) && password === repeatPassword) {
                                                        authService.register(username.trim(), password.trim())
                                                            .then(response => {
                                                                if (response.access_token) {
                                                                    sessionStore.login(response);
                                                                    closeModal();
                                                                    setTimeout(() => {
                                                                        window.location.replace('')
                                                                    }, 200)
                                                                }
                                                                if (response.error) {
                                                                    setError([response.error])
                                                                }
                                                            })
                                                    }
                                                }}
                                        >Register
                                        </button>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
});
Register.displayName = 'Register';
export default Register;
