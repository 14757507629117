import React, {FC, useEffect, useState} from 'react'
import './Account.module.css';
import {UserService} from "../../services/UserService";
import {useSessionStore} from "../../stores/sessionStore";
import InputWithDebounce from "../../components/InputWithDebounce/InputWithDebounce";
import _ from "lodash";
import {AuthenticationService} from "../../services/AuthenticationService";
import {toast} from "react-toastify";
import storageHelper from "../../helpers/storageHelper";
import {useNavigate} from "react-router-dom";

interface AccountProps {
}

const Account: FC<AccountProps> = () => {
    const userService = new UserService();
    const sessionStore = useSessionStore();
    const [user, setUser] = useState<any>(null)
    const [username, setUsername] = useState('')
    const [usernameExists, setUsernameExists] = useState<boolean | undefined>()
    const [password, setPassword] = useState('')
    const [repeatPassword, setRepeatPassword] = useState('')
    const [error, setError] = useState<string[]>([])
    const [oldPassword, setOldPassword] = useState('')
    const navigate = useNavigate()
    const authService = new AuthenticationService();

    useEffect(() => {
        userService.fetchAccount().then(user => {
            setUser(user)
            setUsername(user.username)
        })

        console.log(sessionStore.me());
    }, []);

    const isStrongPassword = (password: string) => {
        // Minimum Length (8 characters)
        if (password.length < 8) {
            console.log("Password must be at least 8 characters long");
            return false;
        }

        // Mix of Uppercase and Lowercase Letters
        if (!/[a-z]/.test(password) || !/[A-Z]/.test(password)) {
            console.log("Password must include both uppercase and lowercase letters");
            return false;
        }

        // Include at Least One Numeric Digit (0-9)
        if (!/\d/.test(password)) {
            console.log("Password must include at least one numeric digit");
            return false;
        }

        // Include at Least One Special Character
        if (!/[!@#$%^&*()_+{}\[\]:;<>,.?~\\/-]/.test(password)) {
            console.log("Password must include at least one special character");
            return false;
        }

        // All checks passed, the password is strong
        return true;
    }


    useEffect(() => {
        const errors = [];

        // Minimum Length (8 characters)
        if (password.length < 8) {
            errors.push("Password must be at least 8 characters long");
        }

        // Mix of Uppercase and Lowercase Letters
        if (!/[a-z]/.test(password) || !/[A-Z]/.test(password)) {
            errors.push("Password must include both uppercase and lowercase letters");
        }

        // Include at Least One Numeric Digit (0-9)
        if (!/\d/.test(password)) {
            errors.push("Password must include at least one numeric digit");
        }

        // Include at Least One Special Character
        if (!/[!@#$%^&*()_+{}\[\]:;<>,.?~\\/-]/.test(password)) {
            errors.push("Password must include at least one special character");
        }

        setError(errors)
    }, [password]);

    useEffect(() => {
        const errors = [];
        if (password !== repeatPassword) {
            errors.push("Password dot not match");
        }
        setError(errors)
    }, [repeatPassword]);

    return (
        <>
            <section className='section-page-header mt-4'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-lg-12 mb-4'>
                            <div className='page-title d-flex justify-content-start align-items-center'>
                                <div className='title-icon'><i className="ti-user"></i></div>
                                <div className='title-content'>
                                    <h1>Account</h1>
                                    <p>Account Settings</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className='section-account section mb-4'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-lg-12'>
                            <div className='card card-style-2'>
                                <h2 className='title'>
                                    <div className='d-flex align-items-center'>
                                        Discord
                                        {
                                            sessionStore.isDiscordVerified() && user &&
                                            <>
                                                {`: ${sessionStore.me().discordUsername}      `}

                                                <i className="fa-solid fa-certificate verified-icon ms-2"></i>
                                                <span className='verified-text'>Verified</span>
                                            </>
                                        }
                                    </div>
                                </h2>

                                <p>
                                    {
                                        sessionStore.isDiscordVerified() ?
                                            'Discord verification was successful. In case you want to link this account to another Discord account, please verify again.' :
                                            'You must verify your account in order to create topics and reply in the forum. Verify your membership with your Discord account now.'
                                    }
                                </p>
                                <div className="mb-3">
                                    <a className="discord-button"
                                       href={`https://discord.com/oauth2/authorize?client_id=${process.env.REACT_APP_DISCORD_CLIENT_ID}&response_type=code&redirect_uri=${encodeURIComponent(`${window.location.origin}/authorize`)}&scope=identify+email`}>
                                        <img src="/discord-logo-white.png"
                                             alt="Discord Logo"/>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className='section-account section mb-4'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-lg-12'>
                            <div className='card card-style-2'>
                                <h2 className='title'>Change Username</h2>
                                <div className="mb-3">
                                    <label htmlFor="userNameInput" className="form-label">Username</label>
                                    <InputWithDebounce value={username} className={'form-control'}
                                                       placeholder={'Enter username'} onChange={(data) => {
                                        let urlStrippedUsername = data.trim()
                                        setUsername(urlStrippedUsername)
                                        if (urlStrippedUsername.length > 0 && username !== urlStrippedUsername ) {
                                            userService.checkIfUserExists(urlStrippedUsername).then(resp => {
                                                setUsernameExists(resp.result)
                                            })
                                        }
                                    }}/>
                                    {
                                        _.isUndefined(usernameExists) ?
                                            <i className='ti-user'></i> :
                                            <>
                                                {
                                                    !usernameExists ?
                                                        <i className='ti-check' title={'username ok'}></i> :
                                                        <i className='ti-close' title={'username exists'}></i>
                                                }
                                            </>
                                    }
                                    {
                                        !_.isUndefined(usernameExists) &&
                                        <>
                                            {
                                                !usernameExists ?
                                                    <div className="valid-feedback">
                                                        Looks good!
                                                    </div> :
                                                    <div className="invalid-feedback">
                                                        Please choose a username.
                                                    </div>
                                            }
                                        </>
                                    }
                                </div>
                                <div className='col-auto'>
                                    <button className='btn btn-primary d-sm-flex' onClick={() => {
                                        if (!_.isUndefined(usernameExists) && !usernameExists) {
                                            authService.changeUsername(username.trim())
                                                .then(response => {
                                                    if (response.error) {
                                                        toast.error(response.error)
                                                    }
                                                    else{
                                                        toast.success('Username changed successfully');
                                                        storageHelper.removeData('dao_guestToken')
                                                        setTimeout(() => {
                                                            navigate('/logout')
                                                        }, 500)
                                                    }
                                                })
                                        }
                                    }}><i className="ti-check me-2"></i>Change
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className='section-account section'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-lg-12'>
                            <div className='card card-style-2'>
                                <h2 className='title'>Change Password</h2>
                                <div className="mb-3">
                                    <label htmlFor="OldPasswordInput" className="form-label">Old Password</label>
                                    <input type="password" className="form-control" id="OldPasswordInput"
                                           required value={oldPassword}
                                           onChange={(event) => {
                                               setOldPassword(event?.target?.value)
                                           }} placeholder="Enter your old password"></input>
                                </div>

                                <div className="mb-3">
                                    <label htmlFor="passwordInput" className="form-label">Password</label>
                                    <input type="password" className="form-control" id="passwordInput"
                                           placeholder="Enter a new password" required value={password}
                                           onChange={(event) => {
                                               setPassword(event?.target?.value)
                                           }}></input>
                                    <i className='ti-key'></i>
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="repeatPasswordInput" className="form-label">Repeat Password</label>
                                    <input type="password" className="form-control" id="passwordInput"
                                           placeholder="Repeat the password" required value={repeatPassword}
                                           onChange={(event) => {
                                               setRepeatPassword(event?.target?.value)
                                           }}></input>
                                    <i className='ti-key'></i>
                                </div>
                                {
                                    error.length > 0 &&
                                    <div className={'error-messages'}>
                                        <ul style={{listStyleType: 'circle'}}>
                                            {error.map((errorMessage, index) => (
                                                <li key={index} className="danger">
                                                    <i className="bi bi-exclamation-diamond-fill me-1"></i>{errorMessage}
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                }
                                <div className='col-auto'>
                                    <button
                                        onClick={() => {
                                            if (isStrongPassword(password) && password === repeatPassword) {
                                                authService.changePassword(oldPassword.trim(), password.trim())
                                                    .then(response => {
                                                        if (response.error) {
                                                            toast.error(response.error)
                                                        }
                                                        else{
                                                            toast.success('Password changed successfully');
                                                            storageHelper.removeData('dao_guestToken')
                                                            setTimeout(() => {
                                                                navigate('/logout')
                                                            }, 500)
                                                        }
                                                    })
                                            }
                                        }}
                                        className='btn btn-primary d-sm-flex'><i className="ti-check me-2"></i>Change
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Account;
