import { AxiosResponse } from "axios";
import apiClient from "../http/apiClient";
import { BaseService } from "./BaseService";
import moment from "moment";

export class ReplyService extends BaseService {
    constructor(props?: any) {
        super(props);
    }

    async fetchAll(): Promise<any> {
        try {
            const response: AxiosResponse = await apiClient.get(
                `${process.env.REACT_APP_BASE_URL}/reply`
            );
            if (response.status === 200) {
                return Promise.resolve(response.data);
            }
        } catch (e) {
            return Promise.reject('Failed to fetch replies');
        }
        return Promise.reject('Failed to fetch replies');
    }

    async save(reply: any): Promise<any> {
        try {
            const response: AxiosResponse = await apiClient.post(
                `${process.env.REACT_APP_BASE_URL}/reply/create`,
                reply
            );
            if (response.status === 201) {
                return Promise.resolve(response.data);
            }
        } catch (e) {
            return Promise.reject('Failed to create reply');
        }
        return Promise.reject('Failed to create reply');
    }

    async update(reply: any, id: string): Promise<any> {
        try {
            const response: AxiosResponse = await apiClient.put(
                `${process.env.REACT_APP_BASE_URL}/reply/${id}/update`,
                reply
            );
            if (response.status === 200) {
                return Promise.resolve(response.data);
            }
        } catch (e) {
            return Promise.reject('Failed to create reply');
        }
        return Promise.reject('Failed to create reply');
    }

    async delete(id: string): Promise<any> {
        try {
            const response: AxiosResponse = await apiClient.delete(
                `${process.env.REACT_APP_BASE_URL}/reply/${id}/delete`
            );
            if (response.status === 200) {
                return Promise.resolve();
            }
        } catch (e) {
            return Promise.reject('Failed to create reply');
        }
        return Promise.reject('Failed to create reply');
    }

    async upvote(id: string): Promise<any> {
        try {
            const response: AxiosResponse = await apiClient.get(
                `${process.env.REACT_APP_BASE_URL}/reply/upvote/${id}`
            );
            if (response.status === 200) {
                return Promise.resolve();
            }
        } catch (e) {
            return Promise.reject('Failed to upvote reply');
        }
        return Promise.reject('Failed to upvote reply');
    }
    async downvote(id: string): Promise<any> {
        try {
            const response: AxiosResponse = await apiClient.get(
                `${process.env.REACT_APP_BASE_URL}/reply/downvote/${id}`
            );
            if (response.status === 200) {
                return Promise.resolve();
            }
        } catch (e) {
            return Promise.reject('Failed to upvote reply');
        }
        return Promise.reject('Failed to upvote reply');
    }
}
