import React, { FC, useEffect, useState } from 'react'
import './DiscordAuthorize.module.css';
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { DiscordService } from "../../services/DiscordService";
import {useSessionStore} from "../../stores/sessionStore";
import _ from "lodash";

interface DiscordAuthorizeProps {
}

const DiscordAuthorize: FC<DiscordAuthorizeProps> = () => {
    const [searchParams, setSearchParams] = useSearchParams();
    const discordService = new DiscordService();
    const sessionStore = useSessionStore()
    const [error, setError] = useState('')
    const navigate = useNavigate();

    useEffect(() => {
        let code = searchParams.get('code')
        if (code) {
            try {
                discordService.fetchToken(code).then(result => {
                    // Handle the result (e.g., store the token in state)
                    if (!_.isEmpty(result)){
                        sessionStore.login(result);
                        setTimeout(() => {
                            navigate('/account');
                        }, 1000)
                    }
                }).catch(error => {
                    // Handle the error (e.g., display an error message)
                    setError(error)
                    console.error(error);
                })
            } catch (error) {
                // Handle the error (e.g., display an error message)
                console.error(error);
            }
        }
    }, []);

    return (
        <>

            <section className='section-account section mb-4'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-lg-12'>
                            <div className='card card-style-2'>
                                <h2 className='title'>Discord Authorize</h2>
                                <div className='not-found'>
                                    {
                                        error.length > 0 ?
                                            <div className='failed-message'>
                                                <i className="alert-icon fa-solid fa-circle-exclamation"></i>
                                                <h1>Something Went Wrong</h1>
                                                <p>Synchronization with your Discord account failed. Make sure the app is active and logged in.</p>

                                                <a className='btn btn-primary mt-5' href='/account'><i className="fa-regular fa-user me-1"></i> Account</a>
                                            </div>
                                            :

                                            <div className='failed-message'>
                                            <i className="success-icon fa-solid fa-circle-check"></i>
                                            <h1>Discord Connection Successful</h1>
                                            <p>Discord synchronization was successful. You will be automatically redirected.</p>

                                        </div>

                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default DiscordAuthorize;
