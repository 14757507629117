import {create} from "zustand";
import _ from "lodash";
import storageHelper from "../helpers/storageHelper";
import { v4 as uuidv4 } from 'uuid';
import {faker} from "@faker-js/faker";
import encryptionHelper from "../helpers/encryptionHelper";


export interface SessionStore {
    username: string,
    email: string,
    setUsername: (username: string) => void
    setEmail: (email: string) => void
    hasSession: () => boolean
    login: (data: any) => void,
    logout: () => void
    isAdmin: () => boolean
    isDiscordVerified: () => boolean
    me: () => any,
    init: () => any
}

export const useSessionStore = create<SessionStore>()((set, get) => {
    const parseJwt = (token: string): Record<string, unknown> | null => {
        try {
            const parts = token.split('.');
            if (parts.length !== 3) {
                throw new Error('The token is invalid');
            }

            const payload = parts[1];
            const decodedPayload = atob(payload.replace(/_/g, '/').replace(/-/g, '+'));

            return JSON.parse(decodedPayload);
        } catch (e) {
            console.error('Failed to parse JWT:', e);
            return null;
        }
    }
    return {
        username: localStorage.getItem('dao_username') || '',
        email: localStorage.getItem('dao_email') || '',
        setUsername: (username: string) => {
            set({username})
        },
        setEmail: (email: string) => {
            set({email})
        },
        hasSession: () => {
            const {username, email} = get();
            return !_.isEmpty(username) && username.length > 0;
        },
        login: (data: any) => {
            storageHelper.setToken(data.access_token)
            storageHelper.setRefreshToken(data.refresh_token)
            storageHelper.setData('dao_username', data.username);
            storageHelper.setData('dao_email', data.email);
            let loginUser = {
                id: parseJwt(data.access_token)?.sub,
                username: data.username,
                email: data.email,
                userType: 'forum'
            }
            storageHelper.setData('dao_guestToken', encryptionHelper.encryptBase64(loginUser));
            set({username: data.username, email: data.email})
        },
        logout: () => {
            storageHelper.removeToken()
            storageHelper.removeRefreshToken()
            storageHelper.removeData('dao_username');
            storageHelper.removeData('dao_email');
            set({username: '', email: ''})
        },
        isAdmin: () => {
            const token = storageHelper.getToken();
            if (token) {
                const parsedToken = parseJwt(token);
                if (parsedToken) {
                    const role: string = _.get(parsedToken, 'role', '') as string;
                    if (role.length > 0)
                        return parseInt(role) <= 0;
                }
            }
            return false;
        },
        isDiscordVerified: () => {
            const token = storageHelper.getToken();
            if (token) {
                const parsedToken = parseJwt(token);
                if (parsedToken) {
                    const discordUsername: string = _.get(parsedToken, 'discordUsername', null) as any;
                    return !_.isEmpty(discordUsername)
                }
            }
            return false;
        },
        me: () => {
            const token = storageHelper.getToken();
            if (token) {
                const parsedToken = parseJwt(token);
                return parsedToken
            }
            return false;
        },
        init: () => {
            const {hasSession} = get();
            let guestToken = storageHelper.getData('dao_guestToken')
            if (!hasSession() && !guestToken) {
                let username = faker.internet.userName();
                let email = faker.internet.email();
                let guestUser = {
                    id: uuidv4(),
                    username: username,
                    email: email,
                    userType: 'guest'
                }
                guestToken = encryptionHelper.encryptBase64(guestUser);
                storageHelper.setData('dao_guestToken', guestToken);

            }
        },
    }
})
