import React, {FC, forwardRef, useEffect, useImperativeHandle, useRef, useState} from 'react';
import {AuthenticationService} from "../../services/AuthenticationService";
import {useSessionStore} from "../../stores/sessionStore";
import {toast} from "react-toastify";

interface LoginProps {
}

const Login = forwardRef<any, LoginProps>(({}, ref) => {

    const authService = new AuthenticationService();

    const [username, setUsername] = useState('')
    const [password, setPassword] = useState('')
    const [error, setError] = useState('')
    const sessionStore = useSessionStore();

    const myModalRef = useRef<HTMLDivElement | null>(null);

    const openModal = () => {
        // Use Bootstrap's modal('show') method to open the modal
        if (myModalRef.current) {
            // @ts-ignore: Bootstrap method
            $(myModalRef.current).modal("show");
        }
    };

    const closeModal = () => {
        // Use Bootstrap's modal('hide') method to close the modal
        if (myModalRef.current) {
            // @ts-ignore: Bootstrap method
            $(myModalRef.current).modal("hide");
        }
    };

    const handleButtonClick = () => {
        // Do something before dismissing the modal if needed
        closeModal();
    };


    useImperativeHandle(ref, () => ({
        show() {
            setError('')
            setUsername('')
            setPassword('')
            openModal();
        },
        close() {
            closeModal();
        }
    }));


    return (
        <>
            <div className="modal fade" id="loginModal" data-bs-backdrop="static" data-bs-keyboard="false"
                 ref={myModalRef}
                 aria-labelledby="loginModalLabel">
                <div className="modal-dialog modal-lg modal-dialog-centered user-modal">
                    <div className="modal-content">
                        <div className="modal-body">
                            <div className='modal-wrapper align-items-center'>
                                <div className='modal-image'>
                                </div>

                                <div className='form-content'>
                                    <div className='user-modal-header'>
                                        <h3>Login</h3>
                                        <button type="button" className="close-modal" onClick={() => {
                                            closeModal()
                                        }}
                                                aria-label="Close"><i className='ti-close'></i></button>
                                    </div>
                                    <div className='user-form needs-validation'>
                                        <div className="form-row mb-3">
                                            <label htmlFor="usernameInput" className="form-label">Username</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                id="usernameInput"
                                                placeholder="Enter username"
                                                required
                                                value={username}
                                                onChange={(event) => {
                                                    setUsername(event?.target?.value)
                                                }}
                                            ></input>
                                            <i className='ti-user'></i>
                                            <div className="valid-feedback">
                                                Looks good!
                                            </div>
                                            <div className="invalid-feedback">
                                                Please choose a username.
                                            </div>
                                        </div>
                                        <div className="form-row mb-3">
                                            <label htmlFor="passwordInput" className="form-label">Password</label>
                                            <input
                                                type="password"
                                                className="form-control"
                                                id="passwordInput"
                                                onChange={(event) => {
                                                    setPassword(event?.target?.value)
                                                }}
                                                value={password}
                                                placeholder="Enter password"
                                                required
                                            ></input>
                                            <i className='ti-key'></i>
                                        </div>
                                        {
                                            error &&
                                            <div className={'error-messages'}>
                                                <span className={'danger'}>
                                                <i className="bi bi-exclamation-diamond-fill me-1"></i>{error}
                                                </span>
                                            </div>
                                        }
                                        <button className='user-button' type="button"
                                                onClick={() => {
                                                    if  (username.trim().length <= 0 || password.trim().length <= 0) {
                                                        toast.warning('Username or password can\'t be empty');
                                                        return
                                                    }
                                                    authService
                                                        .login(username.trim(), password.trim())
                                                        .then(response => {
                                                            if (response.access_token) {
                                                                setError('')
                                                                closeModal();
                                                                sessionStore.login(response);
                                                                setTimeout(() => {
                                                                    window.location.replace('')
                                                                }, 200)
                                                            }
                                                            if (response.error) {
                                                                setError(response.error)
                                                            }
                                                        })
                                                }}
                                        >Login
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
});
Login.displayName = 'Login';
export default Login;
