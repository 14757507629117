import React, {FC, forwardRef, useImperativeHandle, useRef, useState} from 'react';
import _ from "lodash";
import {ProposalService} from "../../../../services/ProposalService";
import MDEditor from "@uiw/react-md-editor";
import {ContextStore} from "@uiw/react-md-editor/src/Context";
import {toast} from 'react-toastify';
import {useProposalStore} from "../../../../stores/proposalStore";

interface NewProposalProps {
}

interface NewProposalRef {
    show: () => void;
    close: () => void;
}

const NewProposal = forwardRef<any, NewProposalProps>(({}, ref) => {
    const offcanvasRef = useRef<HTMLDivElement | null>(null);

    const openOffcanvas = () => {
        if (offcanvasRef.current) {
            // Use "show" instead of "Show"
            //@ts-ignore
            $(offcanvasRef.current).offcanvas('show');
        }
    };

    const closeOffcanvas = () => {
        if (offcanvasRef.current) {
            // Use "hide" instead of "Hide"
            //@ts-ignore
            $(offcanvasRef.current).offcanvas('hide');
        }
    };


    useImperativeHandle(
        ref,
        () => ({
            show: () => {
                setContent('')
                setTitle('')
                setProposal(null)
                openOffcanvas()
            },
            edit: (proposal: any) => {
                setContent(proposal.content)
                setTitle(proposal.title)
                setProposal(proposal);
                openOffcanvas();
            },
            close: () => {
                closeOffcanvas();
            },
        }),
        [openOffcanvas, closeOffcanvas]
    );

    const [proposal, setProposal] = useState<any>(null)
    const [content, setContent] = React.useState<string>("Enter your comment");
    const [title, setTitle] = useState('')
    const tagifyRef = useRef();
    const [tags, setTags] = useState<string[]>([]);
    const [category, setCategory] = useState('');
    const proposalService = new ProposalService();
    const proposalStore = useProposalStore();

    return (
        <>
            <div className="offcanvas offcanvas-bottom message-modal" tabIndex={1} ref={offcanvasRef}
                 aria-labelledby="messageModalLabel">
                <div className="offcanvas-header">
                    <h5 className="offcanvas-title" id="messageModalLabel">
                        Create Proposal
                    </h5>
                    <button type="button" className="close-modal" aria-label="Close" onClick={closeOffcanvas}>
                        <i className="bi bi-x-lg"></i>
                    </button>
                </div>
                <div className="message-body">
                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                <div className="mb-4">
                                    <label htmlFor="proposalHeader" className="form-label">
                                        Proposal Heading:
                                    </label>
                                    <input type="text" className="form-input" id="proposalHeader"
                                           placeholder="Enter a header" value={title} onChange={(event) => {
                                        setTitle(event?.target?.value)
                                    }}/>
                                </div>
                                <div className="mb-3" data-color-mode="dark">
                                    <label htmlFor="proposalMessage" className="form-label">
                                        Message: 
                                        <button type="button" data-bs-toggle="collapse" className='proposal-template-button' data-bs-target="#proposalTemplate" aria-expanded="false" aria-controls="proposalTemplate">
                                        <code> Click for proposal template</code></button>
                                    </label>
                                    <p></p>
                       
                                    <div className="collapse" id="proposalTemplate">
  <div className="proposal-template-body">
  <p>Title of proposal: [Insert a clear, concise title that reflects the proposal's objective.]</p>
  <p>Proposal ID: [will be added by proposal committee]</p>
  <p>Proposer(s): [Name or identifier of the proposer or proposing entity.]</p>
  <p>Submission Date: [Date when the proposal is submitted.]</p>

<h3>1-Abstract</h3>

<p>Summary: A brief overview of the proposal’s aim, anticipated outcomes, and importance to the Hydranet community.</p>
<p>Motivation & Rationale: Why this proposal is necessary, including the problem or opportunity it addresses, and how it aligns with Hydranet’s mission.</p>


<h3>2-Proposal Details</h3>

<p>Key Terms: [Optional] Definitions of specific terms used within the proposal.</p>
<p>Proposed Updates & Objectives: Detailed description of the changes, including technical specifications, and clear objectives outlining expected benefits.</p>
<p>Implementation Overview: Overview of the plan to execute the proposal, including:</p>
<p>Action Steps: Key actions needed with associated costs and resources.</p>
<p>Timeline: Major milestones and schedule from initiation to completion.</p>
<p>Overall Cost: Estimated financial investment required, with a cost breakdown.</p>
<p>Supporting Documentation: Any information supporting the proposal, this can be links to entire documents that explain the propose of the proposal.</p>

<h3>3-Impact Analysis</h3>

<p>Advantages: Benefits and positive outcomes for the Hydranet community.</p>
<p>Disadvantages: Potential challenges or limitations, for a balanced view.</p>

<h3>4-Conclusion & Voting</h3>

<p>Final Thoughts: Summarizing the value proposition to garner support.</p>
<p>Voting Options: [Approval, Rejection, Request for Revision.]</p>
<p>Voting Period: Start and end dates for community voting.</p>
  </div>
</div>
                                    <MDEditor
                                        preview={'edit'}
                                        height={400}
                                        value={content}
                                        onChange={(value?: string, event?: React.ChangeEvent<HTMLTextAreaElement>, state?: ContextStore) => {
                                            setContent(value || '')
                                        }}
                                    />
                                </div>

                                <div className="d-flex justify-content-between align-items-center">
                                    <button className="btn btn-primary" type="button" onClick={() => {
                                        let postToSave: any = {
                                            title,
                                            content,
                                            tags: tags.join(','),
                                            category,
                                            url: _.kebabCase(title)
                                        }
                                        if (proposal) {
                                            proposalService.update(postToSave, proposal.id).then(response => {
                                                toast.success('Proposal has been updated')
                                                proposalStore.fetch(true);
                                                closeOffcanvas()
                                            })
                                        } else {
                                            proposalService.save(postToSave).then(response => {
                                                toast.success('Proposal has been sent')
                                                proposalStore.fetch(true);
                                                closeOffcanvas()
                                            })
                                        }
                                    }}>
                                        <i className="bi bi-send-plus me-2"></i>{`${proposal ? 'Edit' : 'Send'} Now`}
                                    </button>
                                    {/*<button className="btn btn-save" type="button">
                                        <i className="bi bi-floppy me-2"></i>Save as Draft
                                    </button>*/}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
});

NewProposal.displayName = 'NewProposal';
export default NewProposal;
