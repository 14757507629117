import React, {FC, useEffect} from "react";
import {useDebounce} from "../../hooks/useDebounce";

interface UsernameProps {
    user: any
}

const Username: FC<UsernameProps> = (
    {
        user
    }: UsernameProps) => {

    return (
        <>
         <span className='username'>
             {user.discordUsername ?? user.username}
             {
                 user.discordUsername &&
                 <i className="fa-solid fa-certificate ms-2"></i>
             }
            </span>
        </>
    );
};

export default Username;
