import React, { FC, useEffect, useRef, useState } from 'react';

interface NotFoundProps {
}

const NotFound: FC<NotFoundProps> = () => {


    return (
        <>

            <section className='section-not-found section'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-12'>
                            <div className='card card-style-2'>
                                <div className='not-found'>
                                    <i className="alert-icon fa-solid fa-circle-exclamation"></i>
                                    <h1>Page Not Found</h1>
                                    <p>The content you were looking for was not found. It may be a members-only page or temporarily removed.</p>
                                    <a className='btn btn-primary mt-5' href='/'><i className="fa-solid fa-house"></i> Back Home</a>
                                </div>
                            </div>


                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default NotFound;
