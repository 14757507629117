import React, {FC, useEffect, useState} from 'react';
import {Outlet, useParams} from "react-router-dom";
import Header from "../../components/Header/Header";
import "@yaireo/tagify/dist/tagify.css"
import {useProposalStore} from "../../stores/proposalStore";
import {useSessionStore} from "../../stores/sessionStore";
import {ToastContainer} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import 'sweetalert2/dist/sweetalert2.css'

interface LayoutProps {
}

const Layout: FC<LayoutProps> = () => {

    const [loading, setLoading] = useState(false)

    const postStore = useProposalStore();
    const sessionStore = useSessionStore();

    useEffect(() => {
        postStore.fetch(true);
    }, [sessionStore.username]);

    return (
        <>
            {
                loading ?
                    <>Loading</> :
                    <>
                        <ToastContainer/>
                        <Header></Header>
                        <main>
                            <Outlet/>
                        </main>
                    </>
            }
        </>
    )
};

export default Layout;
