import React, {FC, useEffect, useState} from 'react';
import avatar1 from '../../assets/avatar1.jpg';
import avatar2 from '../../assets/avatar2.jpg';
import avatar3 from '../../assets/avatar3.jpg';
import avatar4 from '../../assets/avatar4.jpg';
import avatar5 from '../../assets/avatar5.jpg';
import {useNavigate, useParams} from "react-router-dom";
import {useProposalStore} from "../../stores/proposalStore";
import MDEditor from '@uiw/react-md-editor';
import moment from "moment";
import _ from "lodash";
import {UserRole} from "../../models/enums/UserRole";
import {ContextStore} from "@uiw/react-md-editor/src/Context";
import {Rating, Stack, Tooltip} from "@mui/material";
import {useSessionStore} from "../../stores/sessionStore";
import {ReplyService} from "../../services/ReplyService";
import {toast} from "react-toastify";
import ReplyBox from "./components/ReplyBox/ReplyBox";
import {ProposalService} from "../../services/ProposalService";
import Username from '../../components/Username/Username';
import Swal from "sweetalert2";

interface ProposalDetailProps {
}

const ProposalDetail: FC<ProposalDetailProps> = () => {
    const [content, setContent] = React.useState<string>("Enter your reply");
    const {url} = useParams();
    const proposalStore = useProposalStore()
    const sessionStore = useSessionStore()
    const proposalService = new ProposalService()
    const [proposal, setProposal] = useState<any>()
    const [replyOn, setReplyOn] = useState<boolean>(false)
    const replyService = new ReplyService()
    const [editMode, setEditMode] = useState<boolean>(false)
    const [originalProposal, setOriginalProposal] = useState<any>()
    const navigate = useNavigate();

    useEffect(() => {
        if (proposalStore.proposals.length > 0) {
            if (url) {
                let proposal = proposalStore.fetchByUrl(url);

                if (proposal && !proposal.seen) {
                    proposalService.seen(proposal.id);
                }

                setProposal(proposal)
                setOriginalProposal(proposal)
                if (_.isEmpty(proposal)) {
                    navigate('/404')
                }
            }
        }
    }, [proposalStore.proposals]);

    if (!proposal)
        return <></>

    return (
        <>
            {
                proposal &&
                <section className='section section-proposal-detail'>
                    <div className='container'>
                        <div className='row'>
                            <div className='col-lg-12'>
                                <div className='proposal-details'>
                                    <div className='card card-style-2'>
                                        <div className='proposal-header'>
                                            <div className="d-flex justify-content-between">
                                                <div className="d-flex justify-content-start">
                                                    <h1>
                                                        {
                                                            proposal.locked ?
                                                                <i className="ti-lock me-2"></i> :
                                                                <i className="bi bi-fire me-2"></i>
                                                        }
                                                    </h1>
                                                    <h1>
                                                        {
                                                            (
                                                                editMode &&
                                                                (
                                                                    sessionStore.isAdmin()
                                                                )
                                                            ) ?
                                                                <input type="text" className="form-input"
                                                                       id="proposalHeader"
                                                                       placeholder="Enter a header"
                                                                       value={proposal.title}
                                                                       onChange={(event) => {
                                                                           setProposal({
                                                                               ...proposal,
                                                                               title: (event?.target?.value || '')
                                                                           })
                                                                       }}/> :
                                                                <span>{proposal.title}</span>
                                                        }
                                                    </h1>
                                                </div>
                                                <h1>
                                                    <>
                                                        {
                                                            (
                                                                sessionStore.isAdmin() ||
                                                                (sessionStore.username === proposal.owner.username && !proposal.locked)
                                                            ) &&
                                                            <button title="Edit" className="edit-proposal"
                                                                    style={{fontSize: '20px'}}>
                                                                {
                                                                    !editMode ?
                                                                        <i className="ti-pencil" onClick={() => {
                                                                            setEditMode(true)
                                                                        }}></i> :
                                                                        <i className="ti-close" onClick={() => {
                                                                            setProposal(originalProposal)
                                                                            setEditMode(false)
                                                                        }}></i>
                                                                }
                                                            </button>
                                                        }
                                                    </>
                                                    <>
                                                        {
                                                            (sessionStore.isAdmin()) &&
                                                            <button title="Edit" className="edit-proposal"
                                                                    onClick={() => {
                                                                        Swal.fire({
                                                                            title: "Are you sure?",
                                                                            text: "You won't be able to revert this!",
                                                                            icon: "warning",
                                                                            showCancelButton: true,
                                                                            confirmButtonColor: "#3085d6",
                                                                            cancelButtonColor: "#d33",
                                                                            confirmButtonText: "Yes, delete it!"
                                                                        }).then((result) => {
                                                                            if (result.isConfirmed) {
                                                                                proposalService.delete(proposal.id).then((result) => {
                                                                                    if (result.error) {
                                                                                        toast.error(result.error);
                                                                                    } else {
                                                                                        proposalStore.fetch(true).then(() => {
                                                                                            Swal.fire({
                                                                                                title: "Deleted!",
                                                                                                text: "Your proposal has been deleted.",
                                                                                                icon: "success"
                                                                                            }).then((result) => {
                                                                                                navigate('/')
                                                                                            })
                                                                                        })
                                                                                    }

                                                                                })

                                                                            }
                                                                        });
                                                                    }}
                                                                    style={{fontSize: '20px'}}>
                                                                <i className="ti-trash"></i>
                                                            </button>
                                                        }
                                                    </>
                                                </h1>
                                            </div>

                                            <div className="d-flex align-items-center voting">
                                                <div className='post-bottom-right'>
                                                    <span className='like-buttons'>
                                                        <button onClick={() => {
                                                            if (!sessionStore.hasSession() || !sessionStore.isDiscordVerified()) {
                                                                toast.warning('Only verified members can upvote a reply');
                                                                return
                                                            }
                                                            if (proposal.locked) {
                                                                toast.warning('Locked proposals cannot be rated');
                                                                return
                                                            }
                                                            if (proposal.vote && proposal.vote === 'UPVOTE') return
                                                            proposalService.upvote(proposal.id).then(() => {
                                                                toast.success('Reply has been upvoted')
                                                                proposalStore.fetch(true)
                                                            }).catch(() => {
                                                                toast.error('Reply upvote has been failed')
                                                            })
                                                        }}>
                                                            <i className={`${proposal.vote && proposal.vote === 'UPVOTE' ? 'fa-solid' : 'fa-regular'} fa-thumbs-up`}></i>{proposal.upvoteCount}
                                                        </button>
                                                        <button onClick={() => {
                                                            if (!sessionStore.hasSession() || !sessionStore.isDiscordVerified()) {
                                                                toast.warning('Only verified members can downvote a reply');
                                                                return
                                                            }
                                                            if (proposal.locked) {
                                                                toast.warning('Locked proposals can be rated');
                                                                return
                                                            }
                                                            if (proposal.vote && proposal.vote === 'DOWNVOTE') return
                                                            proposalService.downvote(proposal.id).then(() => {
                                                                toast.success('Reply has been downvoted')
                                                                proposalStore.fetch(true)
                                                            }).catch(() => {
                                                                toast.error('Reply downvote has been failed')
                                                            })
                                                        }}>
                                                            <i className={`${proposal.vote && proposal.vote === 'DOWNVOTE' ? 'fa-solid' : 'fa-regular'} fa-thumbs-down`}></i>{proposal.downvoteCount}
                                                        </button>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='topic-owner'>
                                            <div className='user-avatar'>
                                                <img
                                                    src={`${process.env.REACT_APP_BASE_URL}/image/${proposal.owner.username}`}
                                                    alt={`${proposal.owner.username}_profile_picture`}></img>
                                            </div>
                                            <div className='user-info'>
                                                <Username user={proposal.owner}/>
                                                <p>{_.startCase(UserRole[proposal.owner.role].toString())}</p>
                                            </div>
                                        </div>
                                        {
                                            !editMode ?
                                                <div className='proposal-content'>
                                                    <MDEditor.Markdown source={proposal.content}
                                                                       style={{whiteSpace: 'pre-wrap'}}/>
                                                </div> :
                                                <>
                                                    <MDEditor
                                                        preview={'edit'}
                                                        height={400}
                                                        value={proposal.content}
                                                        onChange={(value?: string, event?: React.ChangeEvent<HTMLTextAreaElement>, state?: ContextStore) => {
                                                            setProposal({
                                                                ...proposal,
                                                                content: (value || '')
                                                            })
                                                        }}
                                                    />
                                                </>
                                        }

                                        <div className='proposal-bottom'>
                                            <div className='proposal-info'>
                                                <span className='topic-status locked'>Active</span>
                                                <span className='topic-started'><i
                                                    className="ti-calendar me-2"></i>{moment(proposal.createdAt).format("DD MMM, YYYY")}</span>
                                                <span className='topic-activity'><i
                                                    className="ti-time me-2"></i>{moment(proposal.createdAt).fromNow()}</span>
                                                <span className='topic-activity'><i
                                                    className="ti-eye me-2"></i>{proposal.seenCount}</span>
                                            </div>
                                            {
                                                !editMode ?
                                                    <div className="d-flex justify-content-end">
                                                        <>
                                                            {
                                                                !proposal.locked &&
                                                                <button className='btn btn-primary ms-2'
                                                                        title='reply'
                                                                        onClick={() => {
                                                                            if (!sessionStore.hasSession() || !sessionStore.isDiscordVerified()) {
                                                                                toast.warning('Only verified members can reply to a proposal')
                                                                                return;
                                                                            }
                                                                            setReplyOn(true)
                                                                            setContent('')
                                                                        }}>
                                                                    <i className="fa-solid fa-reply me-1"></i>Reply
                                                                    Now
                                                                </button>
                                                            }
                                                        </>
                                                    </div> :
                                                    <div className="d-flex justify-content-end">
                                                        <button className='btn btn-primary ms-2'
                                                                title='update'
                                                                onClick={() => {
                                                                    proposalService.update(proposal, proposal.id).then(response => {

                                                                        setEditMode(false);
                                                                        toast.success('Proposal has been updated')
                                                                        proposalStore.fetch(true).then(() => {
                                                                            setTimeout(() => {
                                                                                navigate(`/proposal/${_.kebabCase(proposal.title)}`)
                                                                            }, 20)
                                                                        })
                                                                    })
                                                                }}>
                                                            Update
                                                        </button>
                                                    </div>
                                            }
                                        </div>
                                    </div>
                                </div>

                                {
                                    replyOn &&
                                    <div className='post-container'>
                                        <div className='card card-style-2'>
                                            <Stack direction={'column'} spacing={2}>
                                                <Stack direction={'row'} justifyContent={'space-between'}>
                                                    <span>
                                                        <i className="fa-brands fa-markdown text-white"
                                                           color={'inherit'} style={{fontSize: 22, marginRight: 5}}></i>
                                                        <h4 className={'text-white d-inline-flex'}>Reply to this proposal</h4>
                                                    </span>
                                                    <i className="ti-close text-white" style={{cursor: 'pointer'}}
                                                       onClick={() => {
                                                           if (!sessionStore.hasSession() || !sessionStore.isDiscordVerified()) {
                                                               toast.warning('Only verified members can reply to a proposal')
                                                               return;
                                                           }
                                                           setReplyOn(false)
                                                       }}></i>
                                                </Stack>
                                                <div data-color-mode="dark">
                                                    <MDEditor
                                                        preview={'edit'}
                                                        height={400}
                                                        value={content}
                                                        onChange={(value?: string, event?: React.ChangeEvent<HTMLTextAreaElement>, state?: ContextStore) => {
                                                            setContent(value || '')
                                                        }}
                                                    />
                                                </div>
                                                <Stack direction={'row'} justifyContent={'end'}>
                                                    <button className='btn btn-primary' title='reply' onClick={() => {
                                                        let data = {
                                                            content: content,
                                                            proposalId: proposal.id,
                                                        }
                                                        replyService.save(data).then(() => {
                                                            proposalStore.fetch(true)
                                                            toast.success('Reply has been sent')
                                                            setReplyOn(false)
                                                        })
                                                    }}>
                                                        <i className="fa-regular fa-paper-plane me-1"></i>Send
                                                    </button>
                                                </Stack>
                                            </Stack>
                                        </div>
                                    </div>
                                }
                                {
                                    proposal.replies.length > 0 &&
                                    <div className='post-container mt-5'>
                                        <div className='card card-style-2'>
                                            <div className='replies'>
                                                {
                                                    proposal.replies.map((reply: any, index: number) => (
                                                        <ReplyBox key={index} reply={reply}
                                                                  proposal={proposal}></ReplyBox>
                                                    ))
                                                }
                                            </div>
                                        </div>
                                    </div>
                                }
                            </div>
                            <div className='col-lg-4 d-none'>
                                <div className='card card-style-2 mb-4'>
                                    <div className='contributors'>
                                        <h2>Top Contributors</h2>
                                        <ul>
                                            <li>
                                                <div className='contributor'>
                                                    <div className='user-avatar'>
                                                        <img src={avatar1} alt='Avatar01'></img>
                                                    </div>
                                                    <div className='contributor-details'>
                                                        <h4>Mcfinity</h4>
                                                        <p>Team Member</p>
                                                    </div>
                                                </div>
                                                <div className='contributor-point'>
                                                <span className='me-3'><i
                                                    className="fa-regular fa-comment-dots me-1"></i>720</span>
                                                    <span><i className="fa-regular fa-heart me-1"></i>8</span>
                                                </div>
                                            </li>
                                            <li>
                                                <div className='contributor'>
                                                    <div className='user-avatar'>
                                                        <img src={avatar2} alt='Avatar01'></img>
                                                    </div>
                                                    <div className='contributor-details'>
                                                        <h4>Beljora</h4>
                                                        <p>Team Member</p>
                                                    </div>
                                                </div>
                                                <div className='contributor-point'>
                                                <span className='me-3'><i
                                                    className="fa-regular fa-comment-dots me-1"></i>640</span>
                                                    <span><i className="fa-regular fa-heart me-1"></i>8</span>
                                                </div>
                                            </li>
                                            <li>
                                                <div className='contributor'>
                                                    <div className='user-avatar'>
                                                        <img src={avatar3} alt='Avatar01'></img>
                                                    </div>
                                                    <div className='contributor-details'>
                                                        <h4>Pospatriot</h4>
                                                        <p>Team Member</p>
                                                    </div>
                                                </div>
                                                <div className='contributor-point'>
                                                <span className='me-3'><i
                                                    className="fa-regular fa-comment-dots me-1"></i>420</span>
                                                    <span><i className="fa-regular fa-heart me-1"></i>8</span>
                                                </div>
                                            </li>
                                            <li>
                                                <div className='contributor'>
                                                    <div className='user-avatar'>
                                                        <img src={avatar4} alt='Avatar01'></img>
                                                    </div>
                                                    <div className='contributor-details'>
                                                        <h4>Joe Park</h4>
                                                        <p>Team Member</p>
                                                    </div>
                                                </div>
                                                <div className='contributor-point'>
                                                <span className='me-3'><i
                                                    className="fa-regular fa-comment-dots me-1"></i>360</span>
                                                    <span><i className="fa-regular fa-heart me-1"></i>8</span>
                                                </div>
                                            </li>
                                            <li>
                                                <div className='contributor'>
                                                    <div className='user-avatar'>
                                                        <img src={avatar5} alt='Avatar01'></img>
                                                    </div>
                                                    <div className='contributor-details'>
                                                        <h4>Philipp</h4>
                                                        <p>Team Member</p>
                                                    </div>
                                                </div>
                                                <div className='contributor-point'>
                                                <span className='me-3'><i
                                                    className="fa-regular fa-comment-dots me-1"></i>180</span>
                                                    <span><i className="fa-regular fa-heart me-1"></i>8</span>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div className='card card-style-2 mb-4'>
                                    <div className='hot-topics'>
                                        <h2>Hot Proposals</h2>
                                        <ul>
                                            <li>
                                                <h3><a href="#/" title='title'>DAO Proposal #19 DEX fee discount
                                                    addition to
                                                    tokenomics</a></h3>
                                                <span><i className="fa-regular fa-comment-dots me-1"></i>720</span>
                                            </li>
                                            <li>
                                                <h3><a href="#/" title='title'>DAO Proposal #19 DEX fee discount
                                                    addition to
                                                    tokenomics</a></h3>
                                                <span><i className="fa-regular fa-comment-dots me-1"></i>720</span>
                                            </li>
                                            <li>
                                                <h3><a href="#/" title='title'>DAO Proposal #19 DEX fee discount
                                                    addition to
                                                    tokenomics</a></h3>
                                                <span><i className="fa-regular fa-comment-dots me-1"></i>720</span>
                                            </li>
                                            <li>
                                                <h3><a href="#/" title='title'>DAO Proposal #19 DEX fee discount
                                                    addition to
                                                    tokenomics</a></h3>
                                                <span><i className="fa-regular fa-comment-dots me-1"></i>720</span>
                                            </li>
                                            <li>
                                                <h3><a href="#/" title='title'>DAO Proposal #19 DEX fee discount
                                                    addition to
                                                    tokenomics</a></h3>
                                                <span><i className="fa-regular fa-comment-dots me-1"></i>720</span>
                                            </li>

                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            }
        </>
    );
}

export default ProposalDetail;
